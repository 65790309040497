import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/app.css';
// JS
import 'jquery';

import React, { Component } from 'react';

import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';

import { Error404 } from './components/auth';
import {
  Blog,
  Categoria,
  Post,
} from './components/cms';
import {
  AsesoriaContable,
  AuditoriaComunidad,
  Contacto,
  CreacionEmpresa,
  DeclaracionRenta2022,
  DiagnosticoTributario,
  Empresa,
  Home,
} from './components/home';
/*import {
  Checkout,
  Servicios,
} from './components/store';
 import {
  Contrasena,
  MisDatos,
  Pedidos,
} from './components/usuario'; */
import { cargarUsuario } from './redux/actions/auth/action';
import store from './redux/store';
import ScrollToTop from './scrolltotop';

class App extends Component {
  render = () => {
    store.dispatch(cargarUsuario());
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/servicios" component={Servicios} /> */}
            <Route exact path="/servicios/creacion-empresa" component={CreacionEmpresa} />
            <Route exact path="/servicios/asesorias-contables" component={AsesoriaContable} />
            <Route exact path="/servicios/auditoria-comunidades" component={AuditoriaComunidad} />
            <Route exact path="/servicios/diagnostico-tributario" component={DiagnosticoTributario} />
            <Route exact path="/servicios/declaracion-de-renta-2025" component={DeclaracionRenta2022} />
            <Route exact path="/empresa" component={Empresa} />
            <Route exact path="/contacto" component={Contacto} />
            {/* <Route exact path="/inicio-sesion" component={InicioSesion} /> */}
            {/* <Route exact path="/crear-cuenta" component={Registro} /> */}
            {/* <Route exact path="/recuperar-contrasena" component={RecuperarContrasena} /> */}
            {/* <Route exact path="/nueva-contrasena/:uid/:token" component={NuevaContrasena} /> */}
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:slug" component={Post} />
            <Route exact path="/blog/categoria/:slug" component={Categoria} />
            {/* <PrivateRoute exact path="/cambio-contrasena" component={Contrasena} /> */}
            {/* <PrivateRoute exact path="/mis-datos" component={MisDatos} /> */}
            {/* <PrivateRoute exact path="/mis-pedidos" component={Pedidos} /> */}
            {/* <PrivateRoute exact path="/checkout" component={Checkout} /> */}
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
