import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import ImgLogo from '../../images/logohw.png';
import {
  ImgAsesoriasContables,
  ImgAuditoriaComunidades,
  ImgCreacionEmpresa,
  ImgRenta2022,
} from '../../images/servicios';
import ImgWebpay from '../../images/webpay-black.png';
import {
  guardarPostID,
  obtenerPosts,
} from '../../redux/actions/cms/action';

export class Footer extends Component {

  setPostID = id => {
    const { guardarPostID } = this.props;
    guardarPostID(id)
  }


  render = () => {
    const { posts } = this.props;
    var url_api = process.env.REACT_APP_API_URL;
    return (
      <footer className="main-footer style-two bg-footer">
        <div className="auto-container">
          <div className="widgets-section ">
            <div className="row clearfix">
              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget logo-widget">
                  <div className="logo">
                    <Link to="/"><img src={ImgLogo} alt="logo" width="250" /></Link>
                  </div>
                  <ul className="list-style-one">
                    {/* <li><span className="icon fa fa-map-marker"> Oficina Santiago Centro: Av. Libertador Bernardo O'Higgins 1302, Of. 70</span></li>
                    <li><span className="icon fa fa-map-marker"> Oficina Providencia: Av Providencia 1208, Of. 207</span></li> */}
                    <li><span className="icon fa fa-map-marker"> Oficina Viña del Mar: 12 Norte 785, Of. 406</span></li>
                    <li><span className="icon fa fa-envelope"></span><a target="_blank" rel="noopener noreferrer" href="mailto:contacto@contasi.cl">contacto@contasi.cl</a></li>
                    <li><span className="icon fa fa-phone"></span><a href="tel:+56961922323" target="_blank" rel="noopener noreferrer">+56 9 6192 2323</a></li>
                    <li><span className="icon fa fa-phone"></span><a href="tel:+56323468722" target="_blank" rel="noopener noreferrer">+56 32 3468 722</a></li>
                  </ul>
                </div>
              </div>
              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget news-widget">
                  <h2>Servicios Destacados</h2>
                  <div className="post-block servicios">
                    <div className="block-inner">
                      <div className="image">
                        <Link to="/servicios/declaracion-de-renta-2025"><img src={ImgRenta2022} alt="renta" /></Link>
                      </div>
                      <h3><Link to="/servicios/declaracion-de-renta-2025">Declaración de Renta 2025</Link></h3>
                    </div>
                  </div>
                  <div className="post-block servicios">
                    <div className="block-inner">
                      <div className="image">
                        <Link to="/servicios/asesorias-contables"><img src={ImgAsesoriasContables} alt="asesorias" /></Link>
                      </div>
                      <h3><Link to="/servicios/asesorias-contables">Asesoría Contable</Link></h3>
                    </div>
                  </div>
                  <div className="post-block servicios">
                    <div className="block-inner">
                      <div className="image">
                        <Link to="/servicios/asesorias-contables"><img src={ImgAuditoriaComunidades} alt="asesorias" /></Link>
                      </div>
                      <h3><Link to="/servicios/asesorias-contables">Auditoria de Comunidades</Link></h3>
                    </div>
                  </div>
                  <div className="post-block servicios">
                    <div className="block-inner">
                      <div className="image">
                        <Link to="/servicios/asesorias-contables"><img src={ImgCreacionEmpresa} alt="asesorias" /></Link>
                      </div>
                      <h3><Link to="/servicios/asesorias-contables">Creación de Empresa</Link></h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget news-widget">
                  <h2>Últimas Noticias</h2>
                  {posts && posts.length > 0 ? posts.filter(post => post.is_destacado === true).map((post, x) => (
                    <div className="post-block" key={x}>
                      <div className="block-inner">
                        <div className="image">
                          <Link onClick={this.setPostID.bind(this, post.id)} to={`/blog/${post.slug}`}>
                            {post.foto_nombre ?
                              <img src={`${url_api}/static/blog/${post.foto_nombre}`} alt="foto" />
                              : null}
                          </Link>
                        </div>
                        <h3><Link onClick={this.setPostID.bind(this, post.id)} to={`/blog/${post.slug}`}>{post.titulo}</Link></h3>
                        <div className="post-date"><span>{post.text_fecha_dia}</span> {post.text_fecha_mes}</div>
                      </div>
                    </div>
                  ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-lg-9 col-md-12 col-sm-12">
                <div className="copyright"><i className="fa fa-copyright"></i> Contasi {new Date().getFullYear()} | Todos los derechos reservados</div>
              </div>
              <div className="social-column col-lg-3 col-md-12 col-sm-12">
                <img src={ImgWebpay} className="ml-5" alt="logo" width="100" />
              </div>
              {/*
              <div className="social-column col-lg-6 col-md-12 col-sm-12">
                <ul className="social-icon-one">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/contasi"><span className="fab fa-facebook"></span></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/contasi"><span className="fab fa-twitter"></span></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/contasi"><span className="fab fa-instagram"></span></a></li>
                </ul>
              </div>
                */}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  obtenerPosts: PropTypes.func.isRequired,
  guardarPostID: PropTypes.func.isRequired,
  posts: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  posts: state.cms.posts,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { obtenerPosts, guardarPostID },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
