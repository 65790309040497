import React, {
  Component,
  Fragment,
} from 'react';

import { ImgRenta2022 } from '../../images/servicios';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Diagnostico extends Component {
  render = () => {
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Declaración de Renta 2025" />
        <section className="welcome-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Somos Contasi</div>
                    <h2>DECLARACIÓN DE RENTA 2025</h2>
                  </div>
                  <h6><strong>Por que preferir Contasi?</strong></h6>
                  <p>Nos preparamos todo el año para este momento. Nuestros profesionales están siempre en constante actualización de normativas, leyes, noticias y estudio de estrategias para optimizar los resultados desde el punto de vista tributario.</p>
                  <p>No solo vamos a declarar tu Renta, nosotros nos encargaremos de recomendar todos los beneficios tributarios aplicables a tu empresa, es decir que te explicaremos con palabras sencillas para tomar la mejor decisión.</p>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    <img src={ImgRenta2022} alt="renta" />
                  </div>
                </div>
              </div>
              <div className="content-column col-lg-6 col-md-12 col-sm-12">
                <h6><strong>Utiliza los beneficios de tu régimen tributario</strong></h6>
                <p>Sabías que dependiendo del régimen tributario que tenga la empresa puedes obtener algunos beneficios como rebajar el pago de impuestos como incentivo para la reinversión. Así es, por ejemplo, si el régimen de tu empresa es Pro pyme General tienes este incentivo, entre otros beneficios. Por ello es de suma importancia el tipo de régimen que hayas elegido anteriormente.</p>
                <h6><strong>Pagar el impuesto o esperar la devolución de PPM</strong></h6>
                <p>Si tu empresa tuvo utilidades deberá pagar el porcentaje de impuesto que corresponda al régimen tributario que haya elegido tu equipo contable.</p>
                <p>De lo contrario, si tienes pérdidas y la empresa ha pagado PPM podrá solicitar la devolución de ellos, mediante transferencia a la cuenta corriente de la empresa.</p>
                <h6><strong>Resultado de no hacer la declaración de renta</strong></h6>
                <p>No declarar los impuestos puede llevarte a recibir multas e intereses, incluso el representante legal de la empresa puede quedar con deuda ante la Tesorería General de la República e incluso imposibilitado para representar otras sociedades en el futuro.</p>
              </div>
              <div className="content-column col-lg-6 col-md-12 col-sm-12">
                <h6><strong>Importancia de considerar todos los gastos de la empresa</strong></h6>
                <p>Una cosa son las facturas de compra (afecta o exenta) que puede recibir la empresa, pero también están las boletas de honorarios que se le emitan. Además, hay varios documentos que no siempre están en línea y pueden ser considerados dentro del gasto asociado a la actividad de la empresa. Por ejemplo, ¿cuántas veces has tenido que gastar en taxi para llegar a una reunión? Ese es un gasto asociado a la actividad de la empresa, por lo que debe considerarse.</p>
                <h6><strong>Gastos Rechazados versus Gastos aceptados o del giro</strong></h6>
                <p>Importante es tener claro cual(es) son los gastos que son aceptados por el SII al momento de presentar la Renta, debido a que un gasto Rechazado va asociado a una multa de hasta un 35% del valor de dicho gasto.</p>
                <h6><strong>Rectificaciones Renta Periodos anteriores</strong></h6>
                <p>Se debe tener en cuenta que si las declaraciones anteriores se encuentran objetadas, con observaciones o aceptadas con observaciones, con seguridad la renta del año en curso arrastrara dichos errores por lo que es de vital importancia subsanarlas antes de proceder.</p>
              </div>
            </div>
            <section className="call-to-action-three mt-4">
              <div className="auto-container">
                <div className="inner-container clearfix row">
                  <div className='col-8'>
                    <h3>Contasi te apoya en tu Declaración de Renta 2025</h3>
                  </div>
                  <div className='col-4'><a href='https://wa.me/56961922323' className="contact-btn" target='_blank' rel="noopener noreferrer">Envianos un Whatsapp Ahora <i class="fab fa-whatsapp"></i></a></div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Diagnostico;
